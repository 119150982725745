import Layout from 'components/Layout'
import React from 'react'
import AppStateProvider from '../state'
import Hero from '../components/Hero'
import StepBadges from '../components/StepBadges'
import ArrowBulletList from '../components/ArrowBulletList'
import PhoneScreenshots from '../components/PhoneScreenshots'
import CoveredConsultations from '../components/CoveredConsultations'
import IllnessList from '../components/IllnessList'
import heroImg from '../assets/images/heroImage.svg'
import { useState } from 'react'
function RootIndex() {
  const arrowBulletsText = useState([
    [
      'BOOK AN APPOINTMENT',
      'From the main screen you can book by clicking the top left button. ',
    ],
    [
      'VIDEO CALL',
      'You can have a Video Call with the doctor by clicking the My Appointments button.',
    ],
    [
      'YOUR PROFILE',
      'When you first book, we will ask you for some information for your profile and you will only have to enter this once. You can see this information on your profile page. Please make sure your email address is entered correctly.',
    ],
  ])
  const stepBadgesTexts = useState([
    ['Click Book Appointment', ''],
    [
      'New patients please sign up and complete your profile',
      'Existing patients please sign in',
    ],
    [
      'New patients must validate their email',
      'After signing up, go to your email and click the link to validate your account',
    ],
    [
      'Making a Booking',
      'When you first book, complete your profile info on your profile page. You only need to do this once.',
    ],

    ['Choose the service, doctor and time', ''],
    [
      'The Doctor will call you at your chosen time',
      'You can also use the free SwiftDoc app to book appointments',
    ],
  ])
  return (
    <AppStateProvider>
      <Layout title="How It Works | SwiftDoc">
        <div className="margin-container">
          <Hero
            heroImg={heroImg}
            title="Discover how SwiftDoc works"
            subTitle="Follow these steps to use our online video doctor service"
            pageName="HOW IT WORKS"
          />
          <StepBadges badgesText={stepBadgesTexts} />
          {/* 
          <ArrowBulletList arrowBulletText={arrowBulletsText} />
          */}
          <PhoneScreenshots />
          <div className="lists" style={{ padding: '150px 0' }}>
            <CoveredConsultations />
            <IllnessList />
          </div>
        </div>
      </Layout>
    </AppStateProvider>
  )
}

export default RootIndex
