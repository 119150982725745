import React from 'react'
import { Container } from 'components/PageContainer'
import { useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useEffect } from 'react'
import { Controller, Scene } from 'react-scrollmagic'
import screenshot1 from '../../assets/images/PhoneScreenshots/screenshot1.png'
import screenshot2 from '../../assets/images/PhoneScreenshots/screenshot2.png'
import screenshot3 from '../../assets/images/PhoneScreenshots/screenshot3.png'
import screenshot4 from '../../assets/images/PhoneScreenshots/screenshot4.png'
import './phoneScreenshots.scss'
function PhoneScreenshots() {
  const screenshots = [
    [screenshot1, 'Online doctor consultations'],
    [screenshot2, 'Get instant access to online doctors'],
    [screenshot3, 'Video chat with an Australian doctor'],
    [screenshot4, 'Doctor consultations have never been more convenient'],
  ]
  return (
    <div className="bgWhitePhoneScreenshots">
      <div className="imagesContainer">
        {screenshots.map((item, index) => (
          <div key={index} className="card">
            <div className="cardTitle">
              <span>{item[1]}</span>
            </div>
            <div
              key={index}
              className={'imageContainer' + ' ' + 'image' + index}
            >
              <img src={item[0]} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PhoneScreenshots
