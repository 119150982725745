import React from 'react'
import { Container } from 'components/PageContainer'
import './stepbadges.scss'
function StepBadges({ badgesText }) {
  return (
    <div className="bgWhite">
      <Container>
        <div className="badgesContainer">
          {badgesText[0] !== undefined &&
            badgesText[0].map((badge, index) => (
              <div className="badge" key={index}>
                <div className="badgeNumber">{index + 1}</div>
                <div className="badgeText">
                  <span className="badgeTitle">{badge[0]}</span>
                  {badge[1] !== '' && (
                    <span className="badgeSubText">{badge[1]}</span>
                  )}
                </div>
              </div>
            ))}
        </div>
      </Container>
    </div>
  )
}

export default StepBadges
