import React from 'react'
import { Container } from 'components/PageContainer'
import { useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useEffect } from 'react'
import arrowBlackIcon from '../../assets/icons/Icon_Arrow_Black1.svg'
import './arrowBulletList.scss'
function ArrowBulletList({ arrowBulletText }) {
  return (
    <div className="bgWhite">
      <Container>
        <div className="listContainer">
          {arrowBulletText[0].map((item, index) => (
            <div key={index} className="listItem">
              <div className="firstLine">
                <div className="itemArrow">
                  <img src={arrowBlackIcon} alt="arrow icon" />
                </div>
                <div className="itemTitle">{item[0]}</div>
              </div>
              <div className="itemSubTitle">{item[1]}</div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default ArrowBulletList
